@import "../../assets/scss/variables";

.account {
  width: 100vw;
  display: flex;
  align-items: start;
  justify-content: center;
  min-height: 300px;
  padding: 50px 0 0 0;

  &__details {
    padding: 40px 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid $color3;
    margin-bottom: 40px;
  }
}
