@import "../../assets/scss/variables";

.premiumCard {
  border: 2px solid $color3;
  background: $bgColor2;
  padding: 14px 15px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  span {
    flex-grow: 1;
    text-align: start;
  }

  svg {
    position: absolute;
    color: $goldenColor;
    z-index: 1;
    opacity: 0.4;
  }

  strong {
    display: block;
    padding: 0 15px 0 10px;
  }
}