html {
  min-height: 100%;
  background: rgb(195, 207, 226);
  background: linear-gradient(235deg, $bgColor1 0%, $bgColor2 100%);
}

body {
  text-align: center;
  font-family: 'Rubik', sans-serif;
  min-height: 100%;
  background: url("../images/corner_top.png") no-repeat left $headerSize;
}

.wrap {
  background: url("../images/corner_bottom.png") no-repeat right bottom;
  background-size: 127px 224px;
  flex-grow: 1;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

a {
  color: $themeColor1;
  text-decoration: none;
  transition: color 0.3s;

  &:active, &:hover {
    color: saturate(darken($themeColor1, 10), 10);
    text-decoration: underline;
  }
}

#app {
  .menu {
    background: #FFFFFFAA;
    padding: 20px 10px;
    border-radius: 12px;
    color: $color3;
    display: flex;
    justify-content: space-around;

    > a {
      width: 170px;
      font-size: 13px;
    }
  }

  #screen1 {
    .column-right {
      padding-top: 40px;
    }

    .column-left {
      padding-top: 40px;
      text-align: center;

      #logo {
        margin-bottom: 25px;
        padding-left: 20px;
        width: 180px;
      }
    }
  }

  #screen2 {
    padding-top: 70px;
    padding-bottom: 40px;

    .column-right {
      text-align: left;
    }
  }
}

#privacy-policy {
  padding: 80px 30px 40px;
  text-align: left;
}

h1 {
  font-weight: 600;
  font-size: 50px;
  color: $color1;
}

h2 {
  color: $color1;
  font-weight: 600;
  font-size: 32px;
}

h3 {
  color: $color2;
}

h4 {
  color: $color1;
  font-size: 20px;
  margin-top: 24px;
}

.btn-info {
  background-color: $themeColor1;
  border-color: $themeColor1;
  color: #FFF;

  &:hover, &:active {
    background-color: darken($themeColor1, 10);
    border-color: darken($themeColor1, 10);
    color: #FFF;
  }
}

#account-deletion {
  text-align: left;
}