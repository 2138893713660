@import "../../assets/scss/variables";

.reset {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: $panelColor;
  border: 1px solid $color3;
  padding: 20px 30px 30px 30px;
  width: $loginFormWidth;
}

.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.reset div {
  margin-top: 7px;
}
