
.language-chooser {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 10px;

  > span {
    position: absolute;
    width: 1.5px;
    height: 38px;
    background: #DEDEDE;
    display: inline-block;
    margin-left: -15px;
  }

  > div {
    display: inline-block;
  }

  img {
    //border: solid 2px transparent;
  }

  .selected {
    img {
      //border-color: red;
    }
  }
}