// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');

@import "variables";
@import "styles";
@import "responsive";
