@import "../../assets/scss/variables";

#top-menu {
  height: $headerSize;
  background: white;
  border-bottom: 2px solid $panelColor;

  > .container {
    height: $headerSize;
  }

  .navbar-collapse {
    padding-bottom: 15px;
  }

  .navbar-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .nav-item {
    margin: 0 8px;
  }

  .nav-link {
    padding-left: 0.6rem;
    padding-right: 0.6rem;

    color: $themeColor1;
    text-decoration: none;
    transition: color 0.3s;

    &:active, &:hover {
      color: saturate(darken($themeColor1, 10), 10);
      text-decoration: underline;
    }
  }
}