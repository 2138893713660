
// COLORS
$color1: #2c3e50;
$color2: #555555;
$color3: #AAAAAA;
$panelColor: #DFDFDF;
$themeColor1: #778CA1;
$bgColor1: rgba(195, 207, 226, 1);
$bgColor2: rgba(245, 247, 250, 1);
$goldenColor: #FFD700;

// SIZES
$headerSize: 50px;
$footerSize: 50px;
$loginFormWidth: 330px;