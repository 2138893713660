@import "../../assets/scss/variables";

.login {
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    width: $loginFormWidth;
    flex-direction: column;
    text-align: center;
    background-color: $panelColor;
    border: 1px solid $color3;
    padding: 30px;
  }
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}

.is-invalid {
  border: 1px solid red;
}