@media (max-width: 767px) {
  #app {
    #screen1 {
      .menu {
        > a {
          width: 130px;
        }
      }
    }

    #top-menu {
      .nav-link {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }
    }
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 170%;
    margin-top: 25px;
  }

  h2 {
    font-size: 140%;
  }

  h3 {
    font-size: 130%;
  }

  #basic-nav-dropdown {
    display: none;
  }

  footer {
    a {
      font-size: 120%;
    }
  }
}

@media (max-width: 430px) {
  .navbar-brand {
    margin-right: 0;
  }

  #app {
    .language-chooser {
      margin-right: 5px;
      margin-left: 25px;
    }

    .login-button {
      span {
        display: none;
      }
    }
  }

}

@media (max-width: 300px) {
  #app {
    .nav-link {
      font-size: 80%;
    }

    .language-chooser {
      margin-right: 5px;
      margin-left: 0;

      > span {
        display: none;
      }

      img {
        height: 12px;
      }
    }
  }
}